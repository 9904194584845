.candidateNoteWrapper {
  height: calc(100vh - 288px) !important;
  overflow-y: auto;
}

.extendView {
  height: calc(100vh - 118px) !important;
}

.extendView > div {
  min-height: calc(100vh - 115px) !important;
}

.candidateNote {
  margin: 30px 10px 5px 10px;
}

.candidateHeader {
  display: flex;
  justify-content: space-between;
}

.noteSubject {
  margin-bottom: 10px;
  width: 100%;
}

.notesContext {
  width: 15%;
}

.selectClassName {
  width: 100% !important;
}

.dropdownClassName {
  width: 100% !important;
}

.noteEditor {
  :global {
    .ql-editor {
      height: 100px;
    }
  }
}

.noteSave {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.noteView {
  margin: 0 10px 0 10px;
  padding-bottom: 10px;
}
